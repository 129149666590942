import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra Relationship
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Libra Relationship</h4>
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra Relationship </h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
             <p className="playfair text-black text-xl md:text-3xl">Libra Relationship</p> <br/>
             <p className="playfair text-black text-xl md:text-3xl">Libra as a lover</p><br/>
            You admire being in love and always search for a perfect lover. When you fall in love, you will enjoy wining, dining, and romancing. Your outgoing and positive life approach helps you attract people and can easily attract them. After falling in love, you will overlook your partner's shortcomings and always concentrate on the good aspects of your romantic relationship. You will do everything you can to please your partner. At the same time, you expect to be doted on in return.
            You provide full support and devotion to your partner. You usually don't give up after falling in love and will try additional ways to negotiate with your loved ones. You will be demoralized and degraded if rejected in love and romantic relationships. You will quickly search for a substitute and find a new lover. You have the skill of making your loved ones feel good about yourselves and will bring out the best in your lover. You patiently listen to your beloved's viewpoint before offering yours to maintain peace and harmony in a relationship. <br></br>
            Get insights into your love life with Free Love Horoscope Report!<br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra as a colleague</p><br/>
            Libra natives charm others in the workplace with their friendly demeanors and starry elocution styles. You are incredible communicators and also blessed with diplomacy. You are invariably fair, balanced, and level-headed. These qualities help you be necessary and convert balance in conditions of warring egos and chaos in the workplace. You will retreat behind a mental wall during a stressful work environment as you are harmony lovers. You prefer to stay distant from stressful and confronting situations. Otherwise, you will add sparkle to all the tasks. You are usually detached but cordial with most people so they can use them to get things done the way you want. You will appease all your colleagues so that they will work for you whenever you want. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra as a friend</p><br/>
            You make an excellent companion, and your friends and understandings are pleased to have you in a crowd. You have a quick smile, sharp wit, and the ability to feel others that are very important to you. You are fiercely loyal to your friends. You are masters of charm and can brighten the mood with a snap of the fingers. You bring joy and happiness wherever you go. This is no surprise that you have numerous best friends in your circle. You are adept at connecting with others quickly and love getting to know people. <br></br>
            You are social butterflies and love connecting and having fun with friends. So you host parties and get-togethers often and never miss them. You have many friends from different areas of your life. You want to appear in your best light when meeting people. You sometimes have trouble connecting on a deeper level.
            Every friendship is unique, after all. But according to astrology, some sun signs play better with others. So who are your best and worst friend matches? <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra as a boss</p><br/>
            You are soft, polite, and gentle. You always want to create a peaceful and balanced working culture without conflicts and disagreements. A partnership is the most pleasant state for you. Hence, you would love to partner up in business. You take time to make decisions as you like to analyze things in detail. You introduce unique ideas to your employees and give them time to adapt without pushing them too hard. Your imagination runs wild, and you work hard to make them come true. You hold your goals firmly until they are achieved. You simply hate it when your employees push or fool you. You will ensure all the time that the tasks are going as planned and you can accomplish them by the given deadlines. Moreover, you keep a close eye on the potential of your employees so that you can squeeze out the best from them. To understand the pros and cons of your relationship with your boss, get a fully personalized and handwritten compatibility report for your boss and you. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra Fathers</p><br/>
            Libra fathers are charming, fun, and highly social creatures. These traits help you in your style of parenting. Friends, family, and admirers constantly surround you. You are still kids yourselves at heart. You always wait for the day when you can become a friend to your children rather than just a father. You have great patience that allows you to treat your children with the utmost kindness and justice even in the most unpleasant scenarios. You rarely criticize or punish your kids before giving them enough chances to rectify the situation and change their ways. It is very tough for you to express disappointment or anger towards your kids. So, your children may take your discipline lightly. To overcome this, you firmly stand on your ethics and ensure that your kids do understand when you are serious. You provide your children with understanding and learn the concepts of fairness, discipline, and debate well. <br></br> 
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra Mothers</p><br/>
            You like to keep every part of their lives as balanced as possible. But it becomes a bit difficult when you become a mother. Your passion for extravagance and harmony is what makes you a successful mother. You will ensure your children always get the best of everything and encourage them to be creative and self-dependent. You let them be themselves but expect them to be balanced like you. You always encourage them and consider everything you have to say. It does not matter how trivial it might seem to someone else. Usually, you are calm and compassionate when talking to your children, even when they do something wrong. You pass on our patience and friendliness to them when raising them. You possess a great sense of fairness and often mediate arguments between your children and other family members. You never favor one child and always remain fair. You will never go overboard on a punishment whenever your child is in trouble. You can convert the household chores into a game involving your kids. You present to your kids something they want but are unaware of. Every parent raises their children differently. Know your parenting style according to the zodiac sign. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra as a Child</p><br/>
            You will be slow to choose when you know all the options are best for you. It will take longer to decide as you know that you can do the right things if you do it patiently. You love to be the center of attention at social gatherings and parties. With an innate sense of humor and a desire to be sociable, your shining personality will make you a fun child that people love to be around. You are a born diplomat and possess the ability to weigh both sides of an argument without choosing any side. This makes you the perfect playground peacekeeper. You tend to feel the music more than others and feel relaxing when someone turns on the theme or sings for you. You have an eye on all your precious belongings and an innate desire to see everyone safe and happy. You indulge in playing and socializing so much that it makes you late for other engagements. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra as a Husband</p><br/>
            <p className="font-bold text-orange-500 inline-block mr-2">Best marriage match:</p>Gemini, Leo, Sagittarius, and Aquarius Woman. <br></br>
            <br></br>
            You can't admit anything but a genuine and long-lasting connection with your spouse. You will be the kind of husband who does anything for their partner. Your diplomatic nature helps you focus on settling any conflicts you may have with your partner. So your spouse considers you to be their knight in shining armor. You can charm your lady love in only a few minutes and are very good at demonstrating your feelings, so usually, they rule marriage and partnerships. <br></br>
            <br></br>
            You give importance to your marital relationships and often prioritize your spouse over yourself. You are a natural peacemaker and can easily convince your spouse to calm down whenever there are arguments or differences of opinion. So you are called a smooth operator who can make anyone turn to your side. You can keep your family together by being a good provider and usually offering your beloved luxurious clothes or other expensive gifts. <br></br>
            <br></br>
            You can sometimes become manipulative, making it difficult for your wife to determine where you stand. Also, you tend not to pay attention to minor issues until they become serious problems. You are fair, wise, and a good judge, but you sometimes need to spend some time alone. Thus, your wife may often feel depressed and lonely without you. <br></br>
            <br></br>
            Know your marriage prospects and problems related to marriage with the help of the best available astrology guidance. <br></br>
            <br></br>
            <p className="playfair text-black text-xl md:text-3xl">Libra as a Wife</p><br/>
            You are charming and bound to have one of the most infectious smiles in a room full of people. You are a discreet and generous wife, someone who has an eye for detail and the capacity to view each side of the story. Also, you are an exciting partner and a lifetime companion. The ruling planet Venus makes you beautiful and feminine. You are very romantic and know how to have a beautiful moment in the mundane. You are always willing to try new things to keep the romance alive in your marital life. You are a selfless wife and always think about your husband and keep his need before yourself. You can stay immensely calm and even adapt to virtually any situation. You are an excellent mental interlocutor and can bring harmony to your family life. You can handle family and office with smooth perfection and fresh thoughts. You have a huge friend group and will not neglect your family duties. You provide a lot of attention to your husband and children. You always admire and respect your husband and stand by him in all life situations. <br></br>
            <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
